.chat-item-container {
  width: 100%;

  &__avatar {
    color: var(--text-color-contrast);
    background-color: var(--border-color);
  }

  &__details {
    margin-left: calc(var(--spacing-step) * 2.5);
    width: calc(100% - 50px);
  }

  &__participant {
    font-weight: bold;
    color: var(--dark-indigo);
  }

  &__last-message {
    color: var(--dusk);
  }

  &__last-message-text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    white-space: nowrap;
  }

  &__last-message-time {
    margin-left: auto;
    margin-right: calc(var(--spacing-step) * 2.5);
    white-space: nowrap;
  }
}
