.cmp-card {
  position: relative;
  padding: calc(var(--spacing-step) * 2.5);
  box-shadow: 0 calc(var(--spacing-step) * 0.5) calc(var(--spacing-step) * 4) 0
    rgba(96, 89, 131, 0.08);
  border-radius: var(--spacing-step);
  background-color: var(--color-background);
}

.cmp-card > .cmp-card-section:first-child {
  margin-top: 0;  
}

.cmp-card > .cmp-card-section:last-child {
  margin-bottom: 0;  
}
