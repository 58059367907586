.cmp-input-date__overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.cmp-input-date__overlay-close {
  justify-content: flex-end;
  align-self: stretch;
}
