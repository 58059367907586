.chat-footer {
  width: 100%;
  padding: calc(var(--spacing-step) * 2.5) calc(var(--spacing-step) * 2.5) calc(var(--spacing-step) * 1.25);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-background);

  &__form {
    display: flex;
    flex: 1;
  }

  &__input-container {
    flex: 1;
    background-color: var(--input-background);
  }

  &__input {
    flex: 1;
  }
}
