.cmp-button {
  padding: var(--spacing-step) calc(var(--spacing-step) * 2);
  border-radius: var(--spacing-step);
  border: none;
  font-size: var(--text-size-base);
  font-family: inherit;
  cursor: pointer;
}

.cmp-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.cmp-button--fullwidth {
  width: 100%;
}

.cmp-button--icon {
  background: none;
  display: inline-flex;
  align-items: center;
  font-size: 24px;
}

.cmp-button--outlined {
  background: var(--color-background);
  border: 1px solid var(--primary-color-darker);
}

.cmp-button--text {
  padding-left: 0;
  padding-right: 0;
  background: none;
  text-decoration: underline;
}
