.answers-form__question input[type='radio'] {
  width: 20px;
  height: 20px;
  visibility: hidden;
}

.answers-form__question input[type='radio']:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: var(--color-background);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--color-border);
}

.answers-form__question input[type='radio']:checked:after {
  background-image: url('assets/icons/radio-checked.svg');
  background-position: center;
  background-repeat: no-repeat;
}
