.autocomplete__container {
    position: relative;
}

.autocomplete__input-container {
    position: relative;
}

.autocomplete__suggestion-list {
    position: absolute;
    width: 100%;
    max-height: 250px;
    background: #fff;
    border: 1px solid var(--primary-color-darker);
    border-top: none;
    overflow-y: auto;
    padding: 0;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.autocomplete__clear-btn {
    position: absolute;
    top: 14px;
    right: 2px;
    padding: 0;
    margin: 0;
}