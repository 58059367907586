.cmp-text {
  color: var(--text-color);
}

.cmp-text--multiline {
  word-break: break-word;
}

.cmp-text--bold {
  font-weight: bold;
}

.cmp-text--thin {
  font-weight: 100;
}

.cmp-text--s {
  font-size: calc(var(--text-size-base) * 0.875);
}

.cmp-text--m {
  font-size: var(--text-size-base);
}

.cmp-text--l {
  font-size: 1.125rem;
}

.cmp-text--xl {
  font-size: 1.25rem;
}

.cmp-text--xxl {
  font-size: 1.5rem;
}

.cmp-text--xxxl {
  font-size: 1.875rem;
}

.cmp-text--dark-indigo {
  color: var(--dark-indigo);
}
