.cmp-module-content {
  margin-top: calc(var(--spacing-step) * 7);
  padding: calc(var(--spacing-step) * 2);
  background-color: var(--color-background-content);
  flex: 1;
}

.cmp-module-content--light {
  padding: calc(var(--spacing-step) * 2);
  background-color: var(--color-background);
}

@media not screen and (max-width: 600px) {
  .cmp-module-content {
    padding: calc(var(--spacing-step) * 2) calc(var(--spacing-step) * 5);
  }
}
