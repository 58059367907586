.sign-modal p {
  text-align: center;
}

.selected-items > .cmp-box {
  margin: 8px 0;
}

.selected-item-card {
  gap: 8px;
  border: 1px solid var(--primary-color-darker);
  border-radius: 8px;
  padding: 8px;
}

.poll-question {
  color: var(--text-color);
}
