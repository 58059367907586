.poll-form__questions {
  padding: 16px 0;
}

.poll-form__question {
  margin: 16px 0;
}

.poll-form__main-controls {
  padding: 16px 0;
}
