.participate__title {
  font-size: 1.25rem;
}

.participate__options {
  gap: 8px;
}

.participate__option {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: calc(var(--spacing-step) * 2.5);
  width: 100%;
  padding: calc(var(--spacing-step) * 1.5) calc(var(--spacing-step) * 2.5);
  background-color: var(--color-background-content);
  border-radius: 5px;
}

.participate__option > input {
  width: 38px;
  height: 38px;
  margin: 0;
}

.participate__option > .cmp-text {
  flex: 1;
  text-align: left;
}

.participate__input {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
