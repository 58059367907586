.poll-question {
  margin: 0;
}

.poll-question__answers > ul {
  margin-bottom: 0;
  list-style-type: circle;
}

.poll-question__answer {
  position: relative;
}

.poll-question__answer::marker {
  font-size: 20px;
}

.poll-question__answer input {
  padding-right: 32px;
}

.poll-question__answer > button {
  position: absolute;
  padding: 0;
  right: 4px;
  top: 7px;
  z-index: 1;
}

.poll-question__add-answer {
  text-decoration: underline;
}
