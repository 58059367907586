.topic-tag {
  margin-bottom: 8px;
}

.topic-tag--active {
  background-color: var(--primary-color-darker);
}

.topic-tag--active > .cmp-text {
  color: var(--text-color-contrast);
}

.topic-tag:not(:last-child) {
  margin-right: 16px;
}
