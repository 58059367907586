.cmp-tag {
  display: inline-block;
  line-height: calc(var(--spacing-step) * 4);
  padding-right: calc(var(--spacing-step) * 3);
  padding-left: calc(var(--spacing-step) * 2);
  border-radius: 8px;
  background-color: var(--color-background-inactive);
}

.cmp-tag--container {
  display: flex;
  align-items: center;
  gap: var(--spacing-step);
}

.cmp-tag > .cmp-text {
  font-size: var(--text-size-base);
}

.cmp-tag--selected {
  background-color: var(--primary-color-darker);
}

.cmp-tag--selected > .cmp-text {
  color: var(--text-color-contrast);
}
