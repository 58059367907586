.auth-common-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-content);
}

.auth-common-page__logo {
  height: calc(var(--spacing-step) * 7);
  padding: 24px;
  background-color: var(--primary-color-dark);
}

.auth-common-page__form-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 0;
}

.auth-common-page__form-card > form {
  width: 100%;
}

@media (min-width: 600px) {
  .auth-common-page__form-card {
    width: 400px;
    margin: calc(var(--spacing-step) * 4) auto;
    align-items: center;
    border-radius: 8px;
  }
}