.cmp-box--flex-x {
  display: flex;
  align-items: center;
}

.cmp-box--flex-y {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cmp-box--gapped {
  gap: calc(var(--spacing-step) * 1);
  position: relative;
}

.cmp-box--gapped-double {
  gap: calc(var(--spacing-step) * 2);
  position: relative;
}

.cmp-box--bordered {
  border: 1px solid var(--primary-color-darker);
}
