.account-view p {
  text-align: center;
}

.account-view__user {
  align-items: center;
}

.account-view__navigation {
  margin-top: 32px;
}

.account-view__navigation > .cmp-box:not(:last-child) {
  border-bottom: 2px solid var(--color-divider);
}

.account-view__navigation .nav-item {
  margin: 16px 0;
}

.account-view__navigation a {
  text-decoration: none;
}

.account-view__logout {
  margin-top: calc(var(--spacing-step) * 4);
}
