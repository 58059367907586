.list-items-container {
    overflow-y: auto;
    flex: 1;
    padding: 0 calc(var(--spacing-step) * 2) !important;
    background: var(--input-background);

    &__bottom-target {
        float: left;
        clear: both;
    }
}

.chat-main__container {
    overflow-y: auto;
    flex: 1;
    height: 400px;
}

.chat-main__message_sending {
    background-color: var(--primary-color-darker);
}

.chat-main__message {
    word-break: break-word;
}
