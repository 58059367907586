.topic-view-author {
  display: grid;
  grid-template-columns: 64px auto;
  grid-template-rows: 32px 32px;
  gap: 0 16px;
  align-items: center;
}

.topic-view-author__avatar {
  grid-row: 1 / 3;
  justify-self: stretch;
  align-self: stretch;
  border-radius: 8px;
}

.topic-view-author__avatar__content {
  width: calc(var(--spacing-step) * 7) !important;
  height: calc(var(--spacing-step) * 7) !important;
  font-size: 1.5rem !important;
}

.topic-view-author__user-title {
  grid-row: 1;
  align-items: center;
  align-self: center;
}

.topic-view-author--single-line {
  grid-row: span 2;
}

.topic-view-author__created-at {
  grid-row: 2;
}
