.feedback-form {
  position: fixed;
  bottom: 48px;
  right: 8px;
}

.MuiButtonBase-root.feedback-form__close {
  position: absolute;
  right: -2px;
  top: 11px;
  padding: 8px;
}
