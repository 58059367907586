.comment-card {
  display: grid;
  grid-template-columns: 48px auto 122px;
  grid-template-rows: 48px auto;
  gap: 8px;
  border-radius: 8px;
  padding: 8px;
}

.comment-card__avatar {
  justify-self: stretch;
  align-self: stretch;
  background-color: var(--primary-color);
  border-radius: 8px;
}

.comment-card__author {
  align-self: center;
}

.comment-card__created-at {
  font-size: var(--text-size-smaller);
  justify-self: right;
}

.comment-card__text {
  grid-column: 2 / 4;
}

.comment-card__like-reply {
  grid-column: 3;
  justify-self: right;
}
