.cmpmd-filter-buttons {
  justify-content: space-between;
}

.cmpmd-filter-buttons > .cmp-button {
  height: (calc(var(--spacing-step) * 5));
}

.cmpmd-filter-buttons__clear {
  background-color: var(--color-background);
}
