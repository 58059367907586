.app-header {
  justify-content: space-between;
  background-color: var(--color-background);
  padding: var(--spacing-step) calc(var(--spacing-step) * 2);
}

.app-header.fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.app-header__notifications {
  position: relative;
  margin-right: calc(var(--spacing-step) * 3);
}

.app-header__account--selected {
  background-color: var(--primary-color-darker);
}

.app-header__notification-count {
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: var(--text-color-contrast);
  padding: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  top: 0;
  right: -3px;
}

@media screen and (max-width: 600px) {
  .app-header {
    background-color: var(--primary-color-dark);
  }
}
