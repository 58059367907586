.poll-view__question-answering {
  padding: 8px;
  margin: 0 -8px;
  background-color: var(--primary-color);
}

.poll-view__chairman {
  margin: 8px 0;
}

.poll-view__chairman__title {
  line-height: 2rem;
}

.poll-view__quorum-status {
  background-color: inherit;
}
