.chats-container {
    &__heading {
        padding: calc(var(--spacing-step) / 2) calc(var(--spacing-step) * 2.5);
    }

    &__search {
        margin: calc(var(--spacing-step) * 2.5) 0;
        padding: calc(var(--spacing-step) / 2) calc(var(--spacing-step) * 2.5);
    }

    &__chat-list-wrapper {
        height: 100%;
    }

    &__chat-list {
        width: 100%;
    }

    &__chat-item {
        height: 60px;
        width: 100%;
        border-bottom: solid 1px var(--border-color);
    }

    &__no-messages {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 50px;
        color: var(--blue-grey);
        text-align: center;

        & > div {
          margin: calc(var(--spacing-step) * 1.25) 0;
        }
    }

    &__create-chat-btn-wrapper {
        padding: calc(var(--spacing-step) * 1.25);
    }

    &__create-chat-btn {
        margin-left: auto;
        background-color: var(--accent-color);
        color: var(--primary-color);
    }
}
