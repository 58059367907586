.navigation__header__icon {
  display: flex;
  visibility: hidden;
  font-size: 1.5rem;
  color: var(--text-color-contrast);
}

.navigation__header__logo {
  margin-left: calc(var(--spacing-step) * 2);
}

.navigation__list {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 100%;
  margin: 0;
  padding: var(--spacing-step) calc(var(--spacing-step) * 2);
  background-color: var(--primary-color-dark);
  list-style: none;
  z-index: 1;
}

.navigation__header {
  padding-bottom: var(--spacing-step);
  margin-bottom: calc(var(--spacing-step) * 8);
}

.navigation__items {
  width: 100%;
  padding: 0;
  margin-top: 0;
  list-style: none;
}

.navigation__items--header {
  display: flex;
  margin-bottom: 32px;
}

@media screen and (max-width: 600px) {
  .navigation__list {
    display: none;
    left: -100%;
  }

  .navigation__list.active {
    display: flex;
    left: 0;
  }

  .navigation__toggle {
    display: flex;
  }

  .navigation__header__icon {
    visibility: visible;
    cursor: pointer;
    pointer-events: auto;
  }
}
