@import url('./assets/fonts/stylesheet.css');
@import url('./config/Mui.css');

/* #Check config/styles.ts */

:root {
  --primary-color: #f6f7fa;
  --primary-color-darker: #534d9b;
  --primary-color-dark: #35287e;
  --primary-color-dark-darker: #160b4d;
  --secondary-color: rgb(2, 2, 2);
  --secondary-color-light: #dbf0e4;
  --success-color: cyan;
  --danger-color: red;
  --action-color: #e94135;
  --color-background: white;
  --color-background-content: #f4f6f9;
  --color-background-overlays: rgba(255, 255, 255, 0.75);
  --color-background-inactive: #d5d6e7;
  --color-border: #b4b0c6;
  --color-divider: #eeedf1;
  --color-background-input: rgba(244, 246, 249, 0.43);
  --text-color: darkcyan;
  --text-color-light: #f4f6f9;
  --text-color-contrast: white;
  --text-size-smaller: 0.75rem;
  --text-size-base: 16px;
  --spacing-step: 8px;
  --accent-color: #e94135;
  --border-color: #eeedf1;
  --dark-indigo: #160b4d;
  --dusk: #605983;
  --twilight: #534d9b;
  --whisper: #e7e5f1;
  --blue-grey: #b4b0c6;
  --dark-seafom-green: #4ab577;
  --dark-seafom-green-op: rgba(74, 181, 119, 0.19);
  --input-background: rgba(244, 246, 249, 0.43);
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Leto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
