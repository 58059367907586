.cmp-status-label {
  position: absolute;
  top: -16px;
  right: 16px;
  padding: 4px 8px;
  border-radius: 8px;
  line-height: 24px;
  background-color: var(--primary-color);
}

.cmp-status-label__text {
  margin-left: 8px;
}
