.poll-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.poll-card__title-icons {
  margin-bottom: calc(var(--spacing-step) * 4);
}

.poll-card__chair-expiration {
  justify-content: space-between;
}

.poll-card__icons {
  justify-content: flex-end;
  gap: calc(var(--spacing-step) * 2);
}

.poll-card__icons__completed {
  background-color: var(--secondary-color-light);
}

.poll-card__icons__not-completed {
  background-color: var(--color-background-content);
}
