.navigation-item {
  display: block;
  text-decoration: none;
  opacity: 0.25;
}

.navigation-item .cmp-text {
  color: var(--text-color-contrast);
}

.navigation-item__content {
  padding: var(--spacing-step) 0;
}

.navigation-item__icon {
  justify-content: center;
  width: 32px;
  height: 32px;
}

.navigation-item__selected {
  opacity: 1;
}

.navigation-item__label {
  padding-left: 20px;
}
