.result-item-card {
  gap: 8px;
  padding: 8px;
}

.result-item__answer--my-selected {
  background-color: var(--dark-seafom-green-op);
}

.result-item__answer--my-selected .cmp-text {
  color: var(--dark-seafom-green);
}
