.language-container {
  margin-top: calc(var(--spacing-step) * 4);
}

.language_icon {
  justify-content: center;
  width: 32px;
  height: 32px;
  opacity: 0.25;
}
