.polls-filters {
  flex-wrap: wrap;
  gap: 16px;
}

.poll-tag--active {
  background-color: var(--primary-color-darker);
}

.poll-tag--active > .cmp-text {
  color: var(--text-color-contrast);
}
