.topic-filters {
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  align-items: stretch;
  gap: 8px;
}

.topic-filters__dates {
  justify-content: space-between;
}

.topic-filters__buttons {
  justify-content: space-between;
}
