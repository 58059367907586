.nav-item {
}

.nav-item__marker {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin: 8px;
  background-color: var(--primary-color-darker);
}

.nav-item__text {
  flex: 1;
}
