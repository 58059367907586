.chat-header {
    padding: calc(var(--spacing-step) * 1.25) calc(var(--spacing-step) * 2);
}

.chat-header__right {
    margin-left: calc(var(--spacing-step) * 1.25);
}

.chat-header__title {
    font-family: LetoSans, sans-serif;
    letter-spacing: normal;
    color: var(--dark-indigo);
}
