.comments-sender {
  padding: 0 calc(var(--spacing-step) * 2);
  border-radius: calc(var(--spacing-step) * 1);
  background-color: var(--color-background);
  box-shadow: 0 -4px 2px var(--primary-color);
}

.comments-sender__input {
  flex: 1;
  margin: calc(var(--spacing-step) * 2) 0;
}
