.user-title {
  position: relative;
}

.user-title__apartment {
  position: absolute;
  bottom: 8px;
  margin-left: 8px;
  background-color: var(--primary-color-darker);
  padding: 2px 4px;
  border-radius: 8px;
  font-size: 0.75rem;
  color: var(--text-color-contrast);
}
