.app-root-page {
  height: 100%;
}

.app-root-page__content {
  margin-top: 60px;
  margin-left: 250px;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .app-root-page__content {
    margin-left: 0;
  }
}
