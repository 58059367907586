.message-item {
  margin-top: calc(var(--spacing-step) * 2) !important;

  &__inner-container {
    &--mine {
      flex-direction: row-reverse;
    }
  }

  &__text-container {
    padding: calc(var(--spacing-step) * 3);
    margin-left: calc(var(--spacing-step) * 1.25);
    margin-right: 0;
    border-radius: 8px;
    background-color: var(--whisper);
    color: var(--dark-indigo);

    &--mine {
      background-color: var(--twilight);
      color: var(--text-color-contrast);
      margin-left: 0;
      margin-right: calc(var(--spacing-step) * 1.25);
    }
  }

  &__avatar {
    width: 30px;
    height: 30px;
    align-self: flex-end;
  }

  &__text {
    color: var(--dark-indigo);
    word-break: break-word;

    &--mine {
      color: var(--text-color-contrast);
    }
  }

  &__time {
    color: var(--blue-grey);
    text-align: right;
    margin-top: calc(var(--spacing-step) / 2);
  }
}
