.poll-question__numbers {
  align-self: flex-start;
}

.poll-question-title__number-main {
  color: var(--primary-color-darker);
  font-size: 1.5rem;
}

.poll-question-title__number-rest {
  color: var(--text-color-light);
}
