.answer-line {
  padding: var(--spacing-step);
  border-radius: 5px;
}

.answer-line--even {
  background-color: var(--color-background-content);
}

.answer-line__control {
  min-width: 60px;
  text-align: center;
}