.chat-dialog-container {
    width: 80%;

    &__title {
        & h2 {
            display: flex;
            align-items: center;
        }
        & button {
            margin-left: auto;
        }
    }

    &__field-label {
        color: var(--dark-indigo);
        margin-bottom: calc(var(--spacing-step) * 1.25);
        font-family: LetoSans, sans-serif;
    }

    &__autocomplete {
        background-color: var(--input-background);
        margin-bottom: calc(var(--spacing-step) * 2.5);
        width: 100%;
    }

    &__send-button {
        background-color: var(--accent-color);
    }

    &__message-field {
        width: 100%;

        & textarea {
            min-height: 170px;
            max-height: 170px;
        }
    }
}

.create-chat__button {
    margin-top: calc(var(--spacing-step) * 1.25);
}
